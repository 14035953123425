<template>
    <div>
         <el-tabs v-model="activeName" @tab-click="handleClick">

            <el-tab-pane label="旧料汇总(按材质)" name="first">
                <old-table v-if="activeName=='first'" :rowList="rowList" @fetchData="fetchData" :tableList="tableList" :activeName="activeName" :typeList="typeList" :shopList="shopList" :employeeList="employeeList"></old-table>
            </el-tab-pane>

            <el-tab-pane label="旧料汇总(按售货员)" name="second">
                 <old-table v-if="activeName=='second'" :rowList="rowList" @fetchData="fetchData" :tableList="tableList" :activeName="activeName" :typeList="typeList" :shopList="shopList" :employeeList="employeeList"></old-table> 
            </el-tab-pane>

            <el-tab-pane label="旧料明细表" name="third">
                 <old-table v-if="activeName=='third'" :rowList="rowList" @fetchData="fetchData" :tableList="tableList" :activeName="activeName" :typeList="typeList" :shopList="shopList" :employeeList="employeeList"></old-table>
            </el-tab-pane>

        </el-tabs>

          <div class="pagina">
            <el-pagination
                    :current-page="page"
                    background
                    @size-change="handleSizeChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    :page-size="$store.state.pageSizes[0]"
                    :page-sizes="$store.state.pageSizes"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </div> 

    </div>
</template>

<script>
import oldTable from './components/oldTable.vue'
import {reportCommission,getEmployeeList,getTypeList,getMerchantList } from '@/api/order/offlineOrder/commission'
export default{
    components:{oldTable},
    created(){
        this.reportCommission()
        this.getTypeList()
        this.getMerchantList()
        this.getEmployeeList()
    },
    data(){
        return{
            activeName: 'first',
            employeeList:[], //销售
            shopList:[], //门店
            typeList:[], //品类
            total:0,
            page:1,
            limit:50,
            tableList:[],
            rowList:[ //1.售货员 2.
                {field_alias:'index',field:'index',field_text:'序号',t_width:100,is_sum:0},
                {field_alias:'caizhi_name',field:'caizhi_name',field_text:'成色',t_width:150,is_sum:0},
                {field_alias:'recycle_type_name',field:'recycle_type_name',field_text:'回收类型',t_width:150,is_sum:0},
                // {field_alias:'realname',field:'realname',field_text:'售货员',t_width:150},
                {field_alias:'goods_name',field:'goods_name',field_text:'品类',t_width:150,is_sum:0},
                {field_alias:'PCS',field:'PCS',field_text:'数量',t_width:150,is_sum:1},
                {field_alias:'raw_gold_weight',field:'raw_gold_weight',field_text:'毛重',t_width:150,is_sum:1},
                {field_alias:'Gold_weigth',field:'Gold_weigth',field_text:'净金重',t_width:150,is_sum:1},
                {field_alias:'Msct',field:'Msct',field_text:'主石重',t_width:150,is_sum:1},
                {field_alias:'gold_price',field:'gold_price',field_text:'回收单价(均价)',t_width:150,is_sum:1},
                {field_alias:'Raw_sale_cost',field:'Raw_sale_cost',field_text:'旧料工费',t_width:150,is_sum:1},
                {field_alias:'recycle_price',field:'recycle_price',field_text:'旧料成本',t_width:150,is_sum:1},
                {field_alias:'recycle_commission_money',field:'recycle_commission_money',field_text:'旧料提成',t_width:150,is_sum:1},
                {},

            ],
            type:3,
            form:{
                start_time:'',
                end_time:'',
                sold_user_ids:'',
                hierarchy_id:'',
                goods_type_id:'',
                order_num:'', //单据号
                goods_number:'',//货号
                recycle_type:'',
            },
        }
    },
    methods:{

        fetchData(e){
            this.form={...e.obj}
            console.log(e)
            this.reportCommission()
        },

        //销售提成列表
         reportCommission(){
            const {recycle_type,start_time,end_time,sold_user_ids,hierarchy_id,goods_type_id,goods_number,order_num}= this.form
            let user_ids=sold_user_ids?sold_user_ids.toString():''
            reportCommission({
                start_time,
                end_time,
                hierarchy_id,
                sold_user_ids:user_ids,
                goods_type_id,
                page: this.page, 
                limit: this.limit,
                goods_number,
                order_num,
                recycle_type,
                type:this.type,
            })
            .then((res) => {
                if (res.code === 1) {
                    this.tableList = res.data.list;
                    this.total=res.data.count
                }
            })
        },

        handleClick(tab, event) {
            if(this.activeName=='first'){
                this.type=3
                this.rowList=[
                    {field_alias:'index',field:'index',field_text:'序号',t_width:100,is_sum:0},
                    {field_alias:'caizhi_name',field:'caizhi_name',field_text:'成色',t_width:150,is_sum:0},
                    {field_alias:'recycle_type_name',field:'recycle_type_name',field_text:'回收类型',t_width:150,is_sum:0},
                    // {field_alias:'realname',field:'realname',field_text:'售货员',t_width:150},
                    {field_alias:'goods_name',field:'goods_name',field_text:'品类',t_width:150,is_sum:0},
                    {field_alias:'PCS',field:'PCS',field_text:'数量',t_width:150,is_sum:1},
                    {field_alias:'raw_gold_weight',field:'raw_gold_weight',field_text:'毛重',t_width:150,is_sum:1},
                    {field_alias:'Gold_weigth',field:'Gold_weigth',field_text:'净金重',t_width:150,is_sum:1},
                    {field_alias:'Msct',field:'Msct',field_text:'主石重',t_width:150,is_sum:1},
                    {field_alias:'gold_price',field:'gold_price',field_text:'回收单价(均价)',t_width:150,is_sum:1},
                    {field_alias:'Raw_sale_cost',field:'Raw_sale_cost',field_text:'旧料工费',t_width:150,is_sum:1},
                    {field_alias:'recycle_price',field:'recycle_price',field_text:'旧料成本',t_width:150,is_sum:1},
                    {field_alias:'recycle_commission_money',field:'recycle_commission_money',field_text:'旧料提成',t_width:150,is_sum:1},
                ]
            }else if(this.activeName=='second'){
                this.type=4
                this.rowList=[
                        {field_alias:'index',field:'index',field_text:'序号',t_width:100,is_sum:0},
                        {field_alias:'realname',field:'realname',field_text:'售货员',t_width:150,is_sum:0},
                        {field_alias:'recycle_type_name',field:'recycle_type_name',field_text:'回收类型',t_width:150,is_sum:0},
                        {field_alias:'caizhi_name',field:'caizhi_name',field_text:'成色',t_width:150,is_sum:0},
                        {field_alias:'goods_name',field:'goods_name',field_text:'品类',t_width:150,is_sum:0},
                        {field_alias:'PCS',field:'PCS',field_text:'数量',t_width:150,is_sum:1},
                        {field_alias:'raw_gold_weight',field:'raw_gold_weight',field_text:'毛重',t_width:150,is_sum:1},
                        {field_alias:'Gold_weigth',field:'Gold_weigth',field_text:'净金重',t_width:150,is_sum:1},
                        {field_alias:'Msct',field:'Msct',field_text:'主石重',t_width:150,is_sum:1},
                        {field_alias:'gold_price',field:'gold_price',field_text:'回收单价(均价)',t_width:150,is_sum:1},
                        {field_alias:'Raw_sale_cost',field:'Raw_sale_cost',field_text:'旧料工费',t_width:150,is_sum:1},
                        {field_alias:'recycle_price',field:'recycle_price',field_text:'旧料成本',t_width:150,is_sum:1},
                        {field_alias:'recycle_commission_money',field:'recycle_commission_money',field_text:'旧料提成',t_width:150,is_sum:1},
                ]
            }else if(this.activeName=='third'){
                 this.type=5
                 this.rowList=[
                        {field_alias:'index',field:'index',field_text:'序号',t_width:100,is_sum:0},
                        {field_alias:'hierarchy_name',field:'hierarchy_name',field_text:'门店',t_width:150,is_sum:0},
                        {field_alias:'realname',field:'realname',field_text:'姓名',t_width:150,is_sum:0},
                        {goods_number:'realname',field:'goods_number',field_text:'货号',t_width:150,is_sum:0},
                        {field_alias:'goods_name',field:'goods_name',field_text:'品名',t_width:150,is_sum:0},
                        {field_alias:'goods_type_name',field:'goods_type_name',field_text:'品类',t_width:150,is_sum:0},
                        {field_alias:'pcs',field:'pcs',field_text:'数量',t_width:150,is_sum:1},
                        {field_alias:'Totalweight',field:'Totalweight',field_text:'总件重',t_width:150,is_sum:1},
                        {field_alias:'Gold_weigth',field:'Gold_weigth',field_text:'净金重',t_width:150,is_sum:1},
                        {field_alias:'Msct',field:'Msct',field_text:'主石重',t_width:150,is_sum:1},
                        {field_alias:'Osct',field:'Osct',field_text:'配石重',t_width:150,is_sum:1},
                        {field_alias:'Sale_cost',field:'Sale_cost',field_text:'销售工费',t_width:150,is_sum:1},
                        {field_alias:'Cost_price',field:'Cost_price',field_text:'成本价',t_width:150,is_sum:1},
                        {field_alias:'Sale_price',field:'Sale_price',field_text:'标签价',t_width:150,is_sum:1},
                        {field_alias:'discount',field:'discount',field_text:'折扣',t_width:150},
                        {field_alias:'Sale_price_act',field:'Sale_price_act',field_text:'实售价',t_width:150,is_sum:1},
                        {field_alias:'Actutal_sale_price',field:'Actutal_sale_price',field_text:'实收金额',t_width:150,is_sum:1},
                        {field_alias:'recycle_price',field:'recycle_price',field_text:'旧料抵扣',t_width:150,is_sum:0},
                        {field_alias:'recycle_commission_money',field:'recycle_commission_money',field_text:'旧料提成',t_width:150,is_sum:1},
                ]
            }
            this.reportCommission()
        },

          //获取品类列表
        getTypeList(){
            getTypeList().then(res=>{
                if(res.code==1){
                    this.typeList=res.data
                }
            })
        },  

        //获取门店列表
        getMerchantList(){
            getMerchantList().then(res=>{
                if(res.code==1){
                   let shopList= res.data
                   shopList.map(item=>{
                        item.hierarchy_id=Number.parseInt(item.hierarchy_id)
                    })
                    this.shopList=shopList
                }
            })
        },

        // 获取销售员列表
        getEmployeeList(){
            getEmployeeList().then(res=>{
                if(res.code==1){
                    this.employeeList=res.data
                }
            })
        },

          // 改变分页条数
        handleSizeChange(size) {
            this.limit = size;
            this.reportCommission();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.reportCommission();
        },

    }
   
}
</script>

<style lang='less' scoped>

</style>