<template>
    <div>

         <div class="wrapper" :style="{ height: height }">

          <div class='middle' id="cond-middle">

                    <div class='card' style='display: flex;'>

                        <div class="left" style="display:flex;flex-shrink:0 !important;">
                                 <LocalPrint
                                :show="true"
                                :loading="printLoading"
                                :printRows="rowList"
                                :printData="printData"
                                :title="title"
                                :dialogVisible="localPrintVisible"
                                @onClose="localPrintVisible = false"
                                @onPrint="getGoodsPrint"
                            />

                            <el-button type='primary' style="height:41px;margin-left:10px;" plain @click='handleExport' icon='el-icon-upload2'>导出</el-button>   
                        </div>  

                        <div class="middle" style="display:flex;">
                            
                            <el-form v-model="form" class="formFlex" :inline="true">
                                <el-form-item label="日期" class="flex" style="margin-left:20px;">
                                    <el-date-picker    
                                        v-model="form.time" @change="selectTime"
                                        value-format="yyyy-MM-dd"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                        </el-date-picker>
                                </el-form-item>

                                 <el-form-item class="flex" label="售货员">
                                    <el-select  v-model="form.sold_user_ids" multiple  placeholder="请选择售货员">
                                        <el-option
                                        v-for="item in employeeList"
                                        :key="item.store_user_id"
                                        :label="item.realname"
                                        :value="item.store_user_id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item  class="flex" label="回收类型">
                                    <el-select  v-model="form.recycle_type" placeholder="请选择回收类型">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.type"
                                        :label="item.text"
                                        :value="item.type">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                 <el-form-item  class="flex" label="门店">
                                    <el-select  v-model="form.hierarchy_id" placeholder="请选择门店">
                                        <el-option
                                        v-for="item in shopList"
                                        :key="item.hierarchy_id"
                                        :label="item.hierarchy_name"
                                        :value="item.hierarchy_id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item  class="flex" label="品类">
                                    <el-select  v-model="form.goods_type_id" placeholder="请选择品类">
                                        <el-option
                                        v-for="item in typeList"
                                        :key="item.goods_type_id"
                                        :label="item.title"
                                        :value="item.goods_type_id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item class="flex" label="单据号">
                                     <el-input placeholder="请输入单据号" v-model="form.order_num"></el-input>
                                </el-form-item>

                                 <el-form-item class="flex" label="货号">
                                     <el-input placeholder="请输入货号" v-model="form.goods_number"></el-input>
                                </el-form-item>

                            </el-form>

                            <el-button type="primary" @click="fetchData" style="height: 40px">搜索</el-button >
                            <el-button @click="resetForm" style="height: 40px;margin-right:10px;">重置</el-button> 

                        </div>

                        <div class="right" style="display:flex;">
                             <el-button
                                @click="handleHeight"
                                style="
                                    width: 40px;
                                    height: 40px;
                                    line-height: 36px;
                                    text-align: center;
                                    padding: 0;">
                                    <i class="el-icon-caret-bottom" v-if="height == '60px'"></i>
                                    <i class="el-icon-caret-top" v-else></i>
                                </el-button>
                        </div>

                    </div>
          </div>
         </div>

          <div class='table'>

             <el-table ref="table" :data='tableList' stripe v-loading="loading"  height="650" show-summary>

                <el-table-column
                    type="index"
                    width="50"
                    align="center"
                    label="序号">
                </el-table-column>

                <template v-for='(item, index) in rowList'>
                    
                    <el-table-column
                        align='center'
                        :key='index'
                        :prop="item.field_alias"
                        :label='item.field_text'
                        :width="item.t_width"
                        :filters="item.filed_status"
                        v-if="item.field!='index'">

                        <template slot-scope="scope">
                             {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}    
                        </template>

                    </el-table-column>
                </template>

             </el-table>

          </div>

    </div>
</template>

<script>
import LocalPrint from "@/components/localPrint/Index.vue";
import {reportCommission,downloadList} from '@/api/order/offlineOrder/commission'
export default {
    components:{LocalPrint},
    props:{
        activeName:String,
        shopList:Array,
        typeList:Array,
        employeeList:Array,
        tableList:Array,
        rowList:Array,
    },
    mounted(){
        if(this.activeName=='first'){
            this.type=3
            this.excelTitle='旧料汇总(按材质)'
        }else if(this.activeName=='second'){
            this.type=4
            this.excelTitle='旧料汇总(按售货员)'
        }else if(this.activeName=='third'){
            this.type=5
            this.excelTitle='旧料明细表'
        }
        this.$nextTick(() => {
            const dom = document.getElementById("cond-middle");
            this.offsetHeight = dom.offsetHeight + "px";
          });
    },
    updated(){
        this.$refs['table'].doLayout();
    },
    data(){
        return{
            form:{
                time:'',
                start_time:'',
                end_time:'',
                hierarchy_id:'',
                order_num:'',
                goods_number:'',
                goods_type_id:'',
                recycle_type:'',
            },
            limit:50,
            page:1,
            loading:false,
            localPrintVisible:false,
            printLoading:false,
            title:'旧料提成表',
            printData: {},
            printRows: [], // 打印的行
            offsetHeight:'',
            height:'60px',
            options:[
                {type:1,text:'以旧换新'},
                {type:2,text:'纯回收'},
            ],
            type:3,
            excelTitle:'',

        }
    },
    methods:{

        handleHeight() {
            this.height = this.height === "60px" ? this.offsetHeight : "60px";
        },

        getGoodsPrint(){
        this.printLoading = true;
        const {recycle_type,start_time,end_time,sold_user_ids,hierarchy_id,goods_type_id,goods_number,order_num}= this.form
        let user_ids=sold_user_ids.toString()
        reportCommission({
            start_time,
            end_time,
            hierarchy_id,
            sold_user_ids:user_ids,
            goods_type_id,
            page: this.page, 
            limit: this.limit,
            goods_number,
            order_num,
            recycle_type,
            type:this.type,
        })
        .then((res) => {
          if (res.code == 1) {
            this.printLoading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });

            // res.data.top = [];
            // const arr1 = [
            //   { txt: "门店", value: '美林珠宝', width: "24%" },
            // ];
            // res.data.bottom = [];
            // const arr2 = [
            //   { txt: "打印时间", value: '2022年7月26日10:50:15', width: "24%" },
            // ];
            // res.data.top.push(arr1);
            // res.data.bottom.push(arr2);
            // 计算合计
            this.printData = res;
            const sum = {
              index: "合计",
            };
            const decimal = {};
            this.printData.data.list.forEach((item) => {
            this.rowList.forEach((row) => {
                if(row.is_sum){
                if (row.field_alias!='index') {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {

                    sum[row.field_alias] += Number(item[row.field_alias]);
                        
                        if (!decimal[row.field_alias]) {
                            const arr = String(item[row.field_alias]).split(".");
                            if (arr.length === 2) {
                                decimal[row.field_alias] = arr[1].length;
                            } else {
                                decimal[row.field_alias] = 0;
                            }
                        }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== "index") {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.data.list.push(sum);
            this.printData = {
                //top:res.data.top,
                list: res.data.list,
                // bottom,
            }
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.printLoading = false;
        });
        },

        handleExport(){
            const {start_time,end_time,sold_user_ids,hierarchy_id,goods_type_id,goods_number,order_num}= this.form
            let user_ids=sold_user_ids.toString()
           
            downloadList({
                start_time,
                end_time,
                hierarchy_id,
                sold_user_ids:user_ids,
                goods_type_id,
                page: this.page, 
                limit: this.limit,
                goods_number,
                order_num,
                recycle_type:'',
                type:this.type,
            }).then(res=>{
                console.log(res)
                const blob = new Blob([res], { type: "application/vnd.ms-excel" });
                const a = document.createElement("a");
                a.setAttribute("download", `${this.excelTitle}.xls`);
                a.href = URL.createObjectURL(blob);
                a.click();
            })
        },

        selectTime(e){
            this.form.start_time=e[0]
            this.form.end_time=e[1]
        },

        fetchData(){
              this.$emit('fetchData',{obj:this.form})
        },

        resetForm(){
            this.form={
                time:'',
                start_time:'',
                end_time:'',
                hierarchy_id:'',
                order_num:'',
                goods_number:'',
                goods_type_id:'',
                recycle_type:'',
            }
        },

    }
}
</script>

<style lang='less' scoped>
.flex{display:flex;}
.formFlex{
    display: flex;flex-wrap: wrap;
}

.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  .middle {
    display: flex;
    overflow: hidden;
  }
}

/deep/ .el-table {
    overflow: visible !important;
}
</style>