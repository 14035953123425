import { post } from '@/utils/request';

const getTotalColumns = (data) => post({
    url: '/store/common.column/index',
    data: data,
});

//门店列表
const getMerchantList=data=>post({
    url:'/store/common.Select/getMerchantList',
    data,
})

//搜索配置
const searchTable=data=>post({
    url:'/store/common.search/index',
    data,
})

//提成列表
const CommissionList=data=>post({
    url:'/store/order.Commission/CommissionList',
    data,
})

//品牌列表
const getBandList=data=>post({
    url:'/store/common.Select/getBandList',
    data,
})

//品类列表
const getTypeList=data=>post({
    url:'/store/goods.EntryCtl/getGoodsTypeList',
    data,
})

//新增 修改提成
const commissionSetting=data=>post({
    url:'/store/order.Commission/CommissionSetting',
    data,
})

//删除提成
const CommissionDel=data=>post({
    url:'/store/order.Commission/CommissionDel',
    data
})

//----------------销售列表
//销售提成列表
const reportCommission=data=>post({
    url:'/store/order.Commission/reportCommission',
    data
})

//销售员列表
const getEmployeeList=data=>post({
    url:'/store/authority.User/getEmployee',
    data
})

//销售排行榜
const SalesRanking=data=>post({
    url:'/store/order.Commission/SalesRanking',
    data,
})

// 成色列表
const getMaterialList=data=>post({
    url:'/store/common.Select/getMaterialList',
    data,
})

//导出
const downloadList=data=>post({
    url:'/store/order.Commission/downloadList',
    responseType: 'blob',
    data,
})

//排行榜导出
const downLoadRanklist=data=>post({
    url:'/store/order.Commission/downLoadRanklist',
    responseType: 'blob',
    data,
})


export {
    downLoadRanklist,
    downloadList,
    getMaterialList,
    SalesRanking,
    getEmployeeList,
    reportCommission,
    CommissionDel,
    commissionSetting,
    getTypeList,
    getBandList,
    CommissionList,
    searchTable,
    getMerchantList,
    getTotalColumns,
}